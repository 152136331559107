import { render, staticRenderFns } from "./file.vue?vue&type=template&id=631c2c35&scoped=true&"
import script from "./file.vue?vue&type=script&lang=js&"
export * from "./file.vue?vue&type=script&lang=js&"
import style0 from "./file.vue?vue&type=style&index=0&id=631c2c35&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "631c2c35",
  null
  
)

export default component.exports