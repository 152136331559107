// 接口
import axios from '@/axios'
import { baseUrl } from '@/axios/baseUrl'
// 场景列表/audioapi
export const getGptScene = () => axios.get(baseUrl + '/gptapi/app/getGptScene')
// 对话记录
export const getConversationList = (data) => axios.post(baseUrl + '/gptapi/app/getConversationList', data)
// 新建对话http://gpt.admin.guanyingis.com/gptapi/app/createConversation?app_code=industry&token=wrF9wqPCrcKEe8KjwqrCg8KpdMKowoJ4woHDj3zCrsKpZ8Kud8Kqw5vChsKqwoJkfsKGwpjCog
export const createConversation = (data) => axios.post(baseUrl + '/gptapi/app/createConversation', data)
// 发送问题
// export const sendQuestion = (data) => axios.post(baseUrl + '/gptapi/app/sendQuestion', data)
export const sendQuestion = (data) => axios.post(baseUrl + '/api/industryWisdomAsk/askByStream', data)
// 发送问题（流式输出）
export const sendQuestionByStream = (data) => axios.post(baseUrl + '/api/industryWisdomAsk/askByStream', data)
// 历史消息
export const getMessageList = (data) => axios.post(baseUrl + '/gptapi/app/getMessageList', data)
// 删除对话
export const delConversation = (data) => axios.post(baseUrl + '/api/industryWisdomAsk/deleteQAHistory', data)
// 热门话题
export const getHotTopic = () => axios.post(baseUrl + '/gptapi/app/getHotTopic')
// 问答历史记录
export const getHisList = (data) => axios.post(baseUrl + '/api/industryWisdomAsk/qaHistoryList', data)
