<template>
  <div class="homemain">
    <div class="leftshbtn" :style="{ left: lefthide ? '87px' : '300px' }" @click="hideleft">
      <svg t="1718242874833" v-if="lefthide" class="icon" viewBox="0 0 1024 1024" version="1.1"
        xmlns="http://www.w3.org/2000/svg" p-id="2600" width="128" height="128">
        <path d="M270.387 27.273l484.233 485.222-485.222 484.233z" fill="#ffffff" p-id="2601"></path>
      </svg>
      <svg t="1718242794317" v-else class="icon" viewBox="0 0 1024 1024" version="1.1"
        xmlns="http://www.w3.org/2000/svg" p-id="2390" width="128" height="128">
        <path d="M753.613 996.727l-484.233-485.222 485.222-484.233z" fill="#ffffff" p-id="2391"></path>
      </svg>
    </div>
    <div class="home-left" :style="{ width: lefthide ? '92px' : '300px' }">
      <div class="back" v-if="aiboxitem.name != '晓知因'" @click="goxzy">
        <img src="./components/img/back.png" />
      </div>
      <div class="titleicon">
        <div :class="aiboxitem.iszj ? 'lefticon bordera' : 'lefticon'" v-if="!lefthide || aiboxitem.name == '晓知因'"
          @click="gobackxzy">
          <img style="margin-left: -10px" :src="aiboxitem.gifimg" :style="{
      width: aiboxitem.iszj ? '80px' : '90px',
      marginTop: aiboxitem.iszj ? '0px' : '-20px',
    }" />
        </div>
        <div class="righttit" v-if="!lefthide">
          <div class="botname" :style="{ fontSize: aiboxitem.name.length > 4 ? '36px' : '42px' }">
            {{ aiboxitem.name }}
          </div>
          <!-- <p>{{ aiboxitem.sname }}</p> -->
        </div>
      </div>
      <div class="menulist">
        <div @click="menuclick('hm')" v-if="!aiboxitem.iszj" :class="menutype == 'hm' ? 'menuitem menued' : 'menuitem'">
          <img src="@/assets/images/zqzs/home.png" /><span v-if="!lefthide">首页</span>
        </div>
        <div @click="menuclick('tk')" :class="menutype == 'tk' ? 'menuitem menued' : 'menuitem'">
          <img src="@/assets/images/zqzs/wddh.png" /><span v-if="!lefthide">产业智问</span>
        </div>
        <div @click="menuclick('xl')" :class="menutype == 'xl' ? 'menuitem menued' : 'menuitem'">
          <img src="@/assets/images/zqzs/xlzx.png" /><span v-if="!lefthide">训推中心</span>
        </div>
        <div @click="menuclick('ai')" :class="menutype == 'ai' ? 'menuitem menued' : 'menuitem'"
          v-if="aiboxitem.name == '晓知因' || aiboxitem.name == '绿小影'">
          <img src="@/assets/images/zqzs/yyk.png" /><span v-if="!lefthide">AI应用库</span>
        </div>
        <div @click="menuclick('my')" v-if="!aiboxitem.iszj && aiboxitem.name == '晓知因'"
          :class="menutype == 'my' ? 'menuitem menued' : 'menuitem'">
          <img src="@/assets/images/zqzs/cj.png" /><span v-if="!lefthide">我的机器人</span>
        </div>
        <!-- <div @click="menuclick('cc')" :class="menutype == 'cc' ? 'menuitem menued' : 'menuitem'">
          <img src="@/assets/images/zqzs/ss.png" /><span v-if="!lefthide">搜索机器人</span>
        </div> -->
      </div>

      <span class="bt">
        <!-- <div class="furinbox" @click="changefaiflag"><img src="@/assets/images/zqzs/juicon.png" /></div> -->
        <!-- <div class="vipbtn">升 级 权 益</div> -->
        <!-- <div class="clickbox">
          <img src="@/assets/images/zqzs/gxtj.png" /><span v-if="!lefthide">更新推荐</span>
        </div>
        <div class="clickbox">
          <img src="@/assets/images/zqzs/help.png" /><span v-if="!lefthide">联系与帮助</span>
        </div> -->
      </span>
    </div>
    <div class="home-right" :style="{ width: lefthide ? 'calc(100% - 92px)' : 'calc(100% - 300px)' }">
      <div class="mon"></div>
      <div class="right-top">
        <div class="headnamebox">
          <div class="head">
            <img :src="userInfo.head || require('./components/img/morenhead.png')" />
          </div>
          <div class="name">
            <div class="value">
              {{
      userInfo.nickname ||
      userInfo.phone.substr(0, 3) +
      '****' +
      userInfo.phone.substr(7, 4)
    }}
            </div>
            <!-- <div class="loginout" @click="loginout">
            </div> -->
            <div class="viptip">
              VIP有效期至{{ userInfo.vipTime || '2025-10-28' }}
            </div>
          </div>
          <div class="userclickbox">
            <div class="clickitem" @click="setuserinfo">个人设置</div>
            <div class="clickitem" @click="loginout">退出登录</div>
          </div>
        </div>
      </div>
      <div class="right-bottom" ref="btn">
        <Home v-if="menutype == 'hm'" :aiboxitem="aiboxitem" />
        <AiBot v-if="menutype == 'tk'" :aiboxitem="aiboxitem" :basetalklist="basetalklist" :newinput="newinput"
          @talklistchange="talklistchange" @clear="clearNewInput" />
        <Xcenter v-if="menutype == 'xl'" :aiboxitem="aiboxitem" />
        <AIcenter v-if="menutype == 'ai'" :aibox="aibox" v-on:listenToChildEvent="showMsgfromChild"
          v-on:listenToChildEvent2="showMsgfromChild2" @dingsuccess="dingsuccess" :aiboxitem="aiboxitem"
          :basetalklist="basetalklist" :newinput="newinput" @talklistchange="talklistchange" @clear="clearNewInput" />
        <MyAi v-if="menutype == 'my'" :aibox="aibox" @goxt="goxt" @mybototalk="mybototalk" />
        <UserInfo v-if="menutype == 'user'" @changeuser="changeuser" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import router from '@/router'
import AiBot from './components/aibot.vue'
import Xcenter from './components/xcenter.vue'
import AIcenter from './components/aicenter.vue'
import MyAi from './components/myaibot.vue'
import Home from './components/home.vue'
import UserInfo from './components/userinfo.vue'
import { getaibotlists } from '@/api/bot'


export default {
  name: '',
  components: { AiBot, Xcenter, AIcenter, MyAi, Home, UserInfo },
  data() {
    return {
      menutype: 'tk',
      aiboxitem: [],
      lefthide: false,
      newinput: '',
      basetalklist: [],
      botkeylist: {
        晓知因: 'xzy',
        冠英未来园区: 'park',
        铜小铝: 'copper',
        鲁小班: 'furniture',
        牛么么: 'food',
        红小鬼: 'playing',
        盐小氟: 'villiaumite',
        垚小稀: 'tombarthite',
        绿小影: 'ecology',
        鲁小班2: 'furniture2',
      },
      aibox: [],
      userInfo: []
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getaibotlist()
    if (this.userInfo.phone == '18970800394') {
      this.menutype = 'ai'
    }
    // if (!this.userInfo.phone) {
    //   history.go(0)
    // }
    // console.log(type);

  },
  methods: {
    ...mapMutations('user', ['updateUserInfo']),
    hideleft() {
      this.lefthide = !this.lefthide;
    },
    setuserinfo() {
      console.log('ddd')
      this.menutype = 'user'
    },
    mybototalk(item) {
      console.log('接收到了自建机器人', item)
      this.$router.push('/home')
      this.aiboxitem = {
        headimg: item.profilePicture == 'default.png' ? require('./components/img/morenhead.png') : item.profilePicture,
        name: item.name,
        sname: 'AI机器人',
        botid: item.id,
        gifimg: item.profilePicture == 'default.png' ? require('./components/img/morenhead.png') : item.profilePicture,
        hitxttitle: '我是您的AI智能助手“' + item.name + '”',
        hitxtcontent: item.introduce,
        iszj: true,
        cjlist: []
      }
      this.menutype = 'tk'
    },
    dingsuccess() {
      this.getaibotlist()
    },
    getaibotlist() {
      getaibotlists().then(res => {
        console.log('aibotlist', res)
        const newbotlist = {}
        res.data.forEach(item => {

          const qlist = []
          if (item.robotSceneDTOS) {
            item.robotSceneDTOS.forEach(qitem => {
              const ql = []
              qitem.robotSceneQuestions.forEach(sitem => {
                ql.push(sitem.content)
              })
              qlist.push({
                title: qitem.title,
                content: qitem.content,
                qlist: ql,
                img: qitem.img
              })
            })
          }


          newbotlist[this.botkeylist[item.name]] = {
            headimg: item.profilePicture,
            name: item.name,
            sname: item.fullName,
            botid: item.id,
            gifimg: item.dynamicHead,
            hitxttitle: item.introduce,
            hitxtcontent: item.synopsis,
            subscribe: item.subscribe,
            cjlist: qlist
          }
        })
        console.log('newlist', newbotlist)
        this.aibox = newbotlist

        const type = this.$route.query;

        if (!localStorage.getItem('userInfo')) {
          this.$router.push('/login')
        }
        if (this.userInfo.phone == '13621266846') {
          this.aiboxitem = this.aibox.ecology
          return;
        }
        if (type.type) {
          this.aiboxitem = this.aibox[type.type]
        } else {
          this.aiboxitem = this.aibox.xzy
        }
        this.createsocket()
        console.log('userinfo', JSON.stringify(this.aibox))
      })
    },
    changeuser(uf) {
      console.log('收到的userinfo', uf)
      this.userInfo = uf
    },
    goxzy() {
      this.$router.push('/home')
      if (this.aiboxitem.iszj) {
        this.menutype = 'my'
      } else {
        this.menutype = 'ai'
      }
      this.aiboxitem = this.aibox.xzy


    },
    talklistchange(item) {
      console.log('爸爸接收到了', item)
      this.basetalklist = item
    },
    gobackxzy() {
      this.$router.push('/home')
      setTimeout(() => {
        history.go(0)
      }, 200);
    },
    loginout() {
      this.updateUserInfo()
      setTimeout(() => {
        this.$router.push('/login')
      }, 200);
    },
    goxt() {
      this.menutype = 'xl'
    },
    menuclick(e) {
      if (this.userInfo.phone == '18970800394') {
        this.$message.error('尚未开通此功能，请联系管理员开通')
        return;
      }
      this.menutype = e
    },
    createsocket() {
      // 创建WebSocket对象并指定要连接的URL
      const socket = new WebSocket('wss://gpt-all.web.guanyingis.com/wss');

      // 当与服务器成功建立连接时触发open事件
      socket.onopen = function () {
        console.log('已经与服务器建立了WebSocket连接！');
      };
      const that = this;
      // 当收到消息时触发message事件
      socket.onmessage = function (event) {
        const message = JSON.parse(event.data); // 获取收到的消息内容
        // console.log(message, event);
        that.aiboxitem = {}
        // if (message.content.jump_url == "furniture") {
        //   that.aiboxitem = that.aibox.park;
        //   window.open('http://jiaju-ai.web.shuzhiren.com/#/')
        // } else {
        that.aiboxitem = that.aibox[message.content.jump_url]
        document.title = "AI群智机器人-" + that.aiboxitem.name
        // }
      };

      // 当与服务器断开连接时触发close事件
      socket.onclose = function () {
        console.log('与服务器的WebSocket连接已关闭！');
        // setTimeout(function () { window.location.reload(); }, 1000);
      };

      // 当发生错误时触发error事件
      socket.onerror = function (error) {
        console.error('WebSocket连接发生错误：', error);
      };
    },
    showMsgfromChild(data) {
      this.$nextTick(() => {
        this.menutype = 'tk'
        this.newinput = data
      })
    },
    clearNewInput() {
      console.log('清空')
      this.$nextTick(() => {
        this.newinput = ''
      })


    },
    showMsgfromChild2(data) {
      this.$nextTick(() => {
        this.menutype = 'tk'
        this.newinput = data
      })
    }
  },
}
</script>
<style lang='less' scoped>
.homemain {
  height: 100%;
  width: 100%;
  display: flex;

  .leftshbtn {
    position: fixed;
    left: 300px;
    top: calc(50% - 30px);
    background-color: #161833;
    height: 60px;
    text-align: center;
    width: 30px;
    font-size: 16px;
    border-radius: 0 10px 10px 0;
    color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    z-index: 2;

    svg {
      width: 20px;
    }
  }

  .home-left {
    width: 300px;
    height: 100%;
    overflow: auto;
    background-color: #161833;
    position: relative;
    color: #fff;

    .back {
      position: absolute;
      left: 10px;
      top: -10px;
      cursor: pointer;
      z-index: 1;

      img {
        width: 50px;
        margin-top: 20px;
      }
    }

    .titleicon {
      width: 100%;
      cursor: pointer;
      padding: 20px 10px;
      margin-top: 40px;
      display: flex;
      color: white;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-right: 0px;

      img {
        width: 90px;
        margin-top: -20px;
      }

      .lefticon {
        height: 73px;
        width: 70px;
      }

      .bordera {
        height: 70px;
        width: 70px;
        border-radius: 50%;
        overflow: hidden;
      }

      .righttit {
        padding: 5px 10px;

        .botname {
          font-family: YouSheBiaoTiHei;
          font-size: 42px;
          background: linear-gradient(to right, #9953ff, #53a9ff);
          /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/
          -webkit-background-clip: text;
          /*将设置的背景颜色限制在文字中*/
          -webkit-text-fill-color: transparent;
        }

        /*给文字设置成透明*/
        p {
          font-size: 16px;
          color: #fff;
          font-weight: 700;
        }

        .ic {
          width: 30px;
        }

        .tit {
          width: 170px;
        }
      }
    }

    .menulist {
      width: 100%;
      margin-top: 30px;
      padding: 20px;

      .menuitem {
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 18px;
        background: #ffffff16;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        text-align: left;
        color: #ffffff80;
        padding-left: 10%;

        img {
          height: 22px;
          margin: 5px 10px;
        }
      }

      .menued {
        color: #fff;
        background: linear-gradient(90deg, #9953ff 0%, #53a9ff 100%);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 6px;
      }
    }

    .createbox {
      background-color: #2b3f6010;
      margin: 30px 0;
      margin-bottom: 10px;
      padding: 10px;
      border-radius: 8px;
      text-align: center;
      cursor: pointer;

      img {
        width: 90px;
      }
    }

    .bt {
      position: absolute;
      right: 20px;
      bottom: 10px;
      width: calc(100% - 40px);

      .clickbox {
        background: #ffffff16;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        margin: 15px 0;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        font-size: 18px;
        color: #ffffff;
        justify-items: center;
        text-align: left;
        padding-left: 20%;

        img {
          height: 17px;
          margin: 5px 10px;
        }
      }

      .backclick {
        background: #ffffff16;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        margin: 15px 0;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;
        font-size: 18px;
        color: #ff009d;
        letter-spacing: 4px;
        text-align: center;
        border: 1px solid #ff009d;
      }

      .vipbtn {
        background: linear-gradient(270deg, #17daff 0%, #70a2ff 100%);
        margin-bottom: 30px;
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        font-size: 18px;
        color: #fff;
        justify-content: center;
        text-align: center;
      }

      .furinbox {
        height: 85px;
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-bottom: 40px;
        cursor: pointer;

        img {
          width: 100%;
        }
      }
    }

    .numbox {
      background-color: #2b3f6010;
      padding: 10px;
      border-radius: 8px;
      text-align: center;
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      .numbleft {
        font-size: 16px;
        line-height: 40px;
      }

      .numbright {
        display: flex;

        .czitem {
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 20px;
        }

        .number {
          width: 40px;
          height: 40px;
          background-color: #ffffffa6;
          font-size: 18px;
          line-height: 40px;
          border-radius: 6px;
        }
      }
    }
  }

  .home-right {
    width: calc(100% - 300px);
    height: 100%;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    background-color: #131421;
    position: relative;

    .mon {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      box-shadow: 20px 20px 0 0 #161833;
      position: absolute;
      top: 91px;
      left: -1px;
      transform: rotate(193deg);
    }

    .right-top {
      height: 95px;
      background-color: #161833;
      width: 100%;
      position: relative;

      .headnamebox {
        display: flex;
        flex-wrap: wrap;
        width: 320px;
        padding: 15px;
        position: absolute;
        right: 0px;
        top: 5px;
        border-radius: 20px;
        color: #fff;
        cursor: pointer;

        .name:hover+.userclickbox {
          display: block;
        }

        .userclickbox:hover {
          display: block;
        }

        .userclickbox {
          position: absolute;
          right: 75px;
          width: 150px;
          top: 70px;
          border-radius: 8px;
          background-color: #151730;
          border: 1px solid #d22fa3;
          border-bottom: 0;
          color: #ff009d;
          display: none;
          z-index: 99;

          .clickitem {
            padding: 8px 15px;
            text-align: center;
            font-size: 14px;
            border-radius: 8px;
            font-weight: bold;
            border-bottom: 1px solid #d22fa3;
          }

          .clickitem:hover {
            background-color: #ff009d;
            color: white;
          }
        }

        .head {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
          }
        }

        .jdt {
          width: 100%;
          margin-top: 15px;
        }

        .countbox {
          width: 100%;
          display: flex;
          padding-top: 10px;
          justify-content: space-between;
          color: #fff;
          font-size: 14px;
        }

        .name {
          width: calc(100% - 60px);
          padding-left: 15px;
          padding: 15px;
          /* margin-bottom: 20px; */
          position: relative;
          padding-top: 0;

          .value {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 4px;
            display: flex;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .loginout {
            position: absolute;
            right: -25px;
            top: 0px;
            color: #17daff;
            cursor: pointer;
            font-size: 14px;
            line-height: 30px;
            text-decoration: underline;

            svg {
              width: 20px;
            }
          }

          .viptip {
            color: #ffffff60;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }

    .right-bottom {
      height: calc(100% - 95px);
      width: 100%;

      overflow-y: auto;
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

// /*水平的整个滚动条*/

// ::-webkit-scrollbar:horizontal {
//   height: 24px;
//   background-color: transparent;
// }

/*滚动条轨道*/

/*竖直的滑块*/
::-webkit-scrollbar-thumb {
  background-color: rgb(20, 61, 107);
  border-radius: 2px;
  border-top: 1px solid rgb(20, 61, 107);
  border-bottom: 1px solid rgb(20, 61, 107);
  border-left: 1px solid rgb(20, 61, 107);
}
</style>
