<template>
  <div class="compsmain">
    <div class="left">
      <div class="icon">
        <img src="../img/kqzl.png" alt="">
      </div>
      <div class="text">空气质量<br />传感器</div>
    </div>
    <div class="right" v-loading="loading" element-loading-text="指令发送中.." element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)">
      <div :class="status == 1 ? 'ritem ritemed' : 'ritem'" @click="djclick(1)">
        <div class="icon" @click="djclick(1)">
          <div class="img">
            <img :src="status == 1 ? require('../img/aqi.png') : require('../img/aqi.png')" alt="">
          </div>
        </div>
        <div class="text">空气质量</div>
      </div>
      <div :class="status == 2 ? 'ritem ritemed' : 'ritem'" @click="djclick(2)">
        <div class="icon" @click="djclick(2)">
          <div class="img">
            <img :src="status == 2 ? require('../img/tvoced.png') : require('../img/tvoc.png')" alt="">
          </div>
        </div>

        <div class="text">Tvoc</div>
      </div>
      <div :class="status == 3 ? 'ritem ritemed' : 'ritem'" @click="djclick(3)">
        <div class="icon">
          <div class="img">
            <img :src="status == 3 ? require('../img/pm2_5ed.png') : require('../img/pm2_5.png')" alt="">
          </div>
        </div>
        <div class="text">PM2.5</div>
      </div>
      <div :class="status == 4 ? 'ritem ritemed' : 'ritem'" @click="djclick(4)">
        <div class="icon" @click="djclick(4)">
          <div class="img">
            <img :src="status == 4 ? require('../img/co2ed.png') : require('../img/co2.png')" alt="">
          </div>
        </div>
        <div class="text">CO2</div>
      </div>
      <div class="ritem">
        <div class="dbox" v-if="status == 1">

          <div class="textw">
            {{ resultdata }}
          </div>
        </div>
        <div class="dbox" v-else>
          <div class="iconw">
            <div class="num">{{ resultdata.substr(resultdata.indexOf('：') + 1, (resultdata.indexOf('u') -
      resultdata.indexOf('：') - 1))
              }}</div>
            <div class="dvalue">正常</div>
          </div>
          <div class="textw">
            {{ quelist[status] }}含量正常
          </div>
        </div>

      </div>

      <div class="ritem">
        <img src="../img/tip.png" alt="">
        {{ quelist[status] }}数值为：{{ resultdata.substr(resultdata.indexOf('：') + 1, 9) }}
      </div>


    </div>
  </div>
</template>
<script>
import { baseUrl } from '@/axios/baseUrl';
export default {
  name: '',
  components: {},
  data() {
    return {
      value: false,
      status: '1',
      resultdata: [],
      loading: false,
      quelist: ['', '空气质量', '当前Tvoc', '当前PM2.5', '当前CO2']
    }
  },
  props: {

  },
  mounted() {
    this.fetchdata(this.quelist[this.status])
  },
  methods: {
    fetchdata(que) {
      this.loading = true;
      let xhr = new XMLHttpRequest()
      xhr.open('POST', baseUrl+'/klgapi/chat/gygpt_chat', true)
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Content-type", "application/json");
      xhr.send(JSON.stringify({
        query: que,
        stream: false,
        model_name: '英鸣产业大模型',
        temperature: '0.7',
        mode: '晓知因',
        source: 'CBox产品演示',
        clienttype: 'WEB'
      }));
      const that = this;
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {  //固定写法
          //数据获取成功，获取服务器响应的数据
          const res = JSON.parse(xhr.responseText)
          that.resultdata = res.answer
          console.log('aa', res)
          that.loading = false

        }
      }
    },
    djclick(e) {
      this.status = e
      this.fetchdata(this.quelist[e])
    }
  }
}
</script>
<style lang='less' scoped>
.compsmain {
  height: calc(100% - 40px);
  width: 100%;
  border-radius: 10px 0 0 10px;
  margin-top: 20px;
  display: flex;
  background: linear-gradient(180deg, #181B40 0%, #181A37 100%);
  box-shadow: 2px 2px 2px rgba(17, 19, 38, 0.82);
  border-radius: 7px;

  .left {
    width: 150px;
    background: linear-gradient(180deg, #565AB5 0%, #800A52 100%);
    box-shadow: 2px 2px 2px rgba(17, 19, 38, 0.82);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;

    .icon {
      width: 100%;
      text-align: center;

      img {
        margin: 0 auto;
      }
    }

    .text {
      font-size: 30px;
      margin-top: 20px;
      color: white;
      text-align: center;
    }
  }

  .right {
    width: calc(100% - 150px);
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 20px;

    .ritem {
      background: linear-gradient(275deg, #181B40 0%, #181A37 100%);
      box-shadow: 0px 0px 16px rgba(56, 41, 98, 0.42);
      border-radius: 10px;
      padding: 20px;
      margin: 20px;
      width: calc(25% - 40px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      align-content: center;
      cursor: pointer;

      .icon {
        width: 80px;
        height: 80px;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        align-content: center;
        margin: 10px 0;
        cursor: pointer;

        .img {
          img {
            width: 50px;
          }
        }


      }




      .text {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        align-content: center;
        font-size: 18px;
        color: #53567C;
        text-overflow: ellipsis;


      }

      .valueitem {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 80px;
        font-size: 60px;
        font-weight: 800;
        border-radius: 8px;
        margin: 0 2px;
        text-align: center;
        background: linear-gradient(180deg, #0EDFF0 0%, #1B36F8 100%);
      }

      .dbox {
        display: flex;
        height: 80px;
        justify-content: center;
        align-items: center;

        .iconw {
          background: linear-gradient(180deg, #00A8AF 0%, #2EB954 100%);
          border-radius: 6px;
          width: 80px;
          height: 80px;

          .num {
            font-size: 36px;
          }

          .dvalue {
            text-align: center;
            color: white;
            font-weight: 600;
            font-size: 16px;
          }
        }

        .textw {
          color: white;
          font-size: 20px;
          width: calc(100% - 80px);
          padding-left: 20px;
        }
      }
    }


    .ritemed {
      background: linear-gradient(180deg, #AE0EF0 0%, #F81B9A 100%);

      .text {
        color: #fff
      }
    }

    .ritem:nth-child(5) {
      justify-content: flex-start !important;
    }

    .ritem:nth-child(5),
    .ritem:nth-child(6) {
      width: 100%;
      margin: 40px 20px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      align-content: center;
      font-size: 22px;
      color: white;

      img {
        margin-right: 15px;
      }
    }


  }

}
</style>
