var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"compsmain"},[_vm._m(0),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"right",attrs:{"element-loading-text":"指令发送中..","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.8)"}},[_c('div',{staticClass:"ritem"},[_vm._m(1),_c('div',{staticClass:"content"},[_c('div',{staticClass:"icon"},[_vm._m(2),_c('div',{staticClass:"dvalue"},[_vm._v(_vm._s(_vm.resultdata))])]),_vm._m(3)])]),_c('div',{staticClass:"ritem"},[_vm._m(4),_c('div',{staticClass:"content"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":_vm.fsstatus ? require('../img/winded.gif') : require('../img/ws.png'),"alt":""}})]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.fsstatus ? '运行中' : '已关闭'))])])]),_c('div',{staticClass:"ritem"},[_c('img',{attrs:{"src":require("../img/tip.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.fsstatus ? '风扇正在运行中' : '风扇已关闭')+" ")]),_c('div',{staticClass:"ritem"},[_vm._m(5),_c('div',[_vm._v(" 打开风扇 "),_c('el-switch',{attrs:{"active-color":"#ff009d","inactive-color":"#5B50A9"},on:{"change":_vm.fengshanclick},model:{value:(_vm.fsstatus),callback:function ($$v) {_vm.fsstatus=$$v},expression:"fsstatus"}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../img/ws.png"),"alt":""}})]),_c('div',{staticClass:"text"},[_vm._v("风扇"),_c('br'),_vm._v("控制")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('div',{staticClass:"point"}),_vm._v("当前温度 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../img/temp.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_vm._v("温度"),_c('br'),_vm._v("正常")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('div',{staticClass:"point"}),_vm._v("风扇运行状态 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('div',{staticClass:"point"}),_vm._v("风扇控制 ")])
}]

export { render, staticRenderFns }