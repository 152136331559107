<template>
  <div class="compsmain">
    <div class="left">
      <div class="icon">
        <img src="../img/wifi.png" alt="">
      </div>
      <div class="text">蜂鸣器<br />控制</div>
    </div>
    <div class="right" v-loading="loading" element-loading-text="指令发送中.." element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)">
      <div class="ritem">
        <div class="name">
          <div class="point" />计数器运行状态
        </div>
        <div class="content">
          <div class="icon">
            <div class="num">{{ resultdata.substr(0, resultdata.indexOf('次')) }}</div>
            <div class="dvalue">正常</div>
          </div>
          <div class="text">蜂鸣器的状态取决于计数器的状态，
            计数器≥5则蜂鸣器报警</div>
        </div>
      </div>

      <div class="ritem">
        <img src="../img/tip.png" alt="">
        蜂鸣器监测中..
      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      value: false,
      status: '1',
      loading: false,
      resultdata: [],
    }
  },
  props: {

  },
  mounted() {
    this.fetchdata()
  },
  methods: {
    fetchdata() {
      this.loading = true;
      let xhr = new XMLHttpRequest()
      xhr.open('POST', '/klgapi/chat/gygpt_chat', true)
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Content-type", "application/json");
      xhr.send(JSON.stringify({
        query: '计数器',
        stream: false,
        model_name: '英鸣产业大模型',
        temperature: '0.7',
        mode: '晓知因',
        source: 'CBox产品演示',
        clienttype: 'WEB'
      }));
      const that = this;
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {  //固定写法
          //数据获取成功，获取服务器响应的数据 
          const res = JSON.parse(xhr.responseText)
          that.loading = false;
          that.resultdata = res.answer.substr(res.answer.indexOf('：') + 1)
          console.log(that.resultdata)
        }
      }
    },
  }
}
</script>
<style lang='less' scoped>
.compsmain {
  height: calc(100% - 40px);
  width: 100%;
  border-radius: 10px 0 0 10px;
  margin-top: 20px;
  display: flex;
  background: linear-gradient(180deg, #181B40 0%, #181A37 100%);
  box-shadow: 2px 2px 2px rgba(17, 19, 38, 0.82);
  border-radius: 7px;

  .left {
    width: 150px;
    background: linear-gradient(180deg, #565AB5 0%, #800A52 100%);
    box-shadow: 2px 2px 2px rgba(17, 19, 38, 0.82);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;

    .icon {
      width: 100%;
      text-align: center;

      img {
        margin: 0 auto;
      }
    }

    .text {
      font-size: 30px;
      margin-top: 20px;
      color: white;
      text-align: center;
    }
  }

  .right {
    width: calc(100% - 150px);
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 20px;

    .ritem {
      background: linear-gradient(275deg, #181B40 0%, #181A37 100%);
      box-shadow: 0px 0px 16px rgba(56, 41, 98, 0.42);
      border-radius: 10px;
      padding: 20px;
      margin: 20px;
      width: 100%;

      .name {
        color: white;
        display: flex;
        align-items: center;
        font-size: 22px;

        .point {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          background-color: #ff009d;
          border-radius: 50%;
        }
      }

      .content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;

        .icon {
          width: 120px;
          height: 120px;
          background: linear-gradient(180deg, #62d2ed 0%, #243fee 100%);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          align-content: center;
          margin: 20px 0;

          .num {
            font-size: 48px;
            font-weight: bold;
            color: white;
          }

          .dvalue {
            text-align: center;
            color: white;
            font-weight: 600;
            width: 100%;
            font-size: 20px;
          }
        }

        .text {
          width: calc(100% - 160px);
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          align-content: center;
          margin-left: 20px;
          font-size: 22px;
          color: white;

        }
      }


    }

    .ritem:nth-child(2) {
      width: 100%;
      margin: 40px 20px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      align-content: center;
      font-size: 22px;
      color: white;

      img {
        margin-right: 15px;
      }
    }


  }

}
</style>
