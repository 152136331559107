<template>
  <div class="compsmain">
    <div class="left">
      <div class="icon">
        <img src="../img/power.png" alt="">
      </div>
      <div class="text">电机<br />控制</div>
    </div>
    <div class="right" v-loading="loading" element-loading-text="指令发送中.." element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)">
      <div class="ritem">
        <div :class="status == 1 ? 'icon iconed' : 'icon'" @click="djclick(1)">
          <div class="img">
            <img :src="status == 1 ? require('../img/zhengzhuaned.png') : require('../img/zhengzhuan.png')" alt="">
          </div>
        </div>
        <div class="text">电机正转</div>
      </div>
      <div class="ritem">
        <div :class="status == 2 ? 'icon iconed' : 'icon'" @click="djclick(2)">
          <div class="img">
            <img :src="status == 2 ? require('../img/fanzhuaned.png') : require('../img/fanzhuan.png')" alt="">
          </div>
        </div>

        <div class="text">电机反转</div>
      </div>
      <div class="ritem">
        <div :class="status == 3 ? 'icon iconed' : 'icon'" @click="djclick(3)">
          <div class="img">
            <img :src="status == 3 ? require('../img/opened.png') : require('../img/open.png')" alt="">
          </div>
        </div>
        <div class="text">电机关停</div>
      </div>

      <div class="ritem">
        <img src="../img/tip.png" alt="">
        {{ status == 1 ? '电机正在正传..' : status == 2 ? '电机正在反转..' : '电机已经关停' }}
      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      value: false,
      status: '1',
      loading: false,
      resultdata: [],
      quelist: ['', '电机正转', '电机反转', '电机关停']
    }
  },
  props: {

  },
  mounted() {
    this.fetchdata(this.quelist[this.status])
  },
  methods: {
    fetchdata(que) {
      this.loading = true;
      let xhr = new XMLHttpRequest()
      xhr.open('POST', '/klgapi/chat/gygpt_chat', true)
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Content-type", "application/json");
      xhr.send(JSON.stringify({
        query: que,
        stream: false,
        model_name: '英鸣产业大模型',
        temperature: '0.7',
        mode: '晓知因',
        source: 'CBox产品演示',
        clienttype: 'WEB'
      }));
      const that = this;
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {  //固定写法
          //数据获取成功，获取服务器响应的数据 
          const res = JSON.parse(xhr.responseText)
          console.log(res)
          that.loading = false
          that.$message({
            type: 'success',
            message: res.answer
          });

        }
      }
    },
    djclick(e) {
      this.status = e
      this.resultdata = []
      setTimeout(() => {
        this.fetchdata(this.quelist[e])
      }, 100);

    }
  }
}
</script>
<style lang='less' scoped>
.compsmain {
  height: calc(100% - 40px);
  width: 100%;
  border-radius: 10px 0 0 10px;
  margin-top: 20px;
  display: flex;
  background: linear-gradient(180deg, #181B40 0%, #181A37 100%);
  box-shadow: 2px 2px 2px rgba(17, 19, 38, 0.82);
  border-radius: 7px;

  .left {
    width: 150px;
    background: linear-gradient(180deg, #565AB5 0%, #800A52 100%);
    box-shadow: 2px 2px 2px rgba(17, 19, 38, 0.82);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;

    .icon {
      width: 100%;
      text-align: center;

      img {
        margin: 0 auto;
      }
    }

    .text {
      font-size: 30px;
      margin-top: 20px;
      color: white;
      text-align: center;
    }
  }

  .right {
    width: calc(100% - 150px);
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 20px;

    .ritem {
      background: linear-gradient(275deg, #181B40 0%, #181A37 100%);
      box-shadow: 0px 0px 16px rgba(56, 41, 98, 0.42);
      border-radius: 10px;
      padding: 20px;
      margin: 20px;
      width: calc(33.3% - 40px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      align-content: center;

      .icon {
        width: 120px;
        height: 120px;
        background: linear-gradient(180deg, #181A37 0%, #222546 100%);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        align-content: center;
        margin: 20px 0;
        cursor: pointer;

        .img {
          img {
            width: 60px;
          }
        }

        .dvalue {
          text-align: center;
          color: white;
          font-weight: 600;
          width: 100%;
          font-size: 20px;
        }
      }

      .iconed {
        background: linear-gradient(180deg, #F00E39 0%, #F81B9A 100%);
        border-radius: 50%;
      }

      .text {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        align-content: center;
        margin-left: 20px;
        font-size: 22px;
        color: white;

      }


    }

    .ritem:nth-child(4) {
      width: 100%;
      margin: 40px 20px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      align-content: center;
      font-size: 22px;
      color: white;

      img {
        margin-right: 15px;
      }
    }


  }

}
</style>
