<template>
  <div class="klgmain">

    <div class="klgcontent">
      <div class="klgtablebox">
        <div class="nodatabox" @click="goupload" v-if="filelist.length == 0" >
          <img  src="../img/uploadimg.png" alt="" >
          <div class="nodatatxt">点击图片,录入知识库吧</div>
          <!-- <div class="nodatatxt"><el-button size="small" @click="goupload">上传文档</el-button></div> -->
        </div>
        <span v-else>
          <div class="upbtndiv">
            <!-- <span class="filecount">共<span class="cnum">{{ filelist.length }}</span>个文件，已训练<span class="cnum">{{
        filelist.length }}</span>个</span> -->
            <div class="upbtn" @click="goupload">上传文档</div>

          </div>
          <el-table :data="filelist" height="380" border style="width: 100%">
            <el-table-column align="center" type="index" center label="序号" width="80" />
            <el-table-column prop="file_name" label="文件名称" />
            <el-table-column align="center" label="文件大小" width="120">
              <template slot-scope="scope">
                <div>{{ scope.row.file_size ? parseFloat(scope.row.file_size / 1024).toFixed(2) : 0 }}KB</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="创建时间" width="180">
              <template slot-scope="scope">
                <div>{{ scope.row.create_time && scope.row.create_time.replace('T', ' ') }}</div>
              </template>
            </el-table-column>
            <el-table-column align="left" label="操作" width="220">
              <template slot-scope="scope">
                <div>
                  <el-button size="mini" v-if="scope.row.is_review == 0 && !isscing" type="primary"
                    @click="createquelist(scope.row.file_name, scope.$index)">生成问答列表</el-button>
                  <el-button size="mini" v-if="scope.row.is_review == 1" type="primary"
                    @click="showquelist(scope.row.file_name)">审阅问题</el-button>
                  <el-button size="mini" v-if="scope.row.is_review == -1" type="primary" disabled>生成中..</el-button>
                  &nbsp;
                  <el-popconfirm title="确定删除选中文件吗？" @confirm="delfile(scope.row.file_name)">
                    <el-button slot="reference" size="mini" type="danger">删除</el-button>
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="No" label="状态" width="180"> -->
            <!-- </el-table-column> -->
          </el-table>

          <div class="pagelist">
            <div class="1left">
              <!-- <el-pagination background page-size="5" :total="filelist.length">
              </el-pagination> -->
            </div>
            <div class="right1">{{ aibox.name }}文档集已有文件{{ filelist.length }}个</div>
          </div>
        </span>


      </div>



    </div>

    <el-dialog center :title="'审阅问题（待审核' + cquelist.length + '条）'" :append-to-body="true" :visible="shstatus"
      width="600px" :before-close="shClose">
      <div class="nodata" v-if="cquelist.length == 0">暂无待审核问题</div>
      <div class="mydialog" v-else>
        <p style="margin: 0 0 10px 0;">问题：</p>
        <el-input placeholder="请输入问题" v-model="cqtitle">
        </el-input>
        <p style="margin: 30px 0 10px 0;">答案：</p>
        <el-input placeholder="请输入答案" type="textarea" v-model="cqans" :rows="10">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer" v-if="cquelist.length > 0">
        <el-button size="small" :disabled="cqindex == 0" @click="pagec(0)">上一个</el-button>
        <el-button size="small" type="danger" @click="qyquestions">全部弃用</el-button>
        <el-button size="small" type="danger" @click="qyque">弃用</el-button>
        <el-button size="small" type="primary" @click="saveque">转存</el-button>
        <el-button size="small" type="primary" @click="savequestions">全部转存</el-button>
        <el-button size="small" :disabled="cqindex == (cquelist.length - 1)" @click="pagec(1)">下一个</el-button>
      </span>
    </el-dialog>
    <el-dialog title="上传文档" :append-to-body="true" :visible="uploadstatus" width="630px" :before-close="shClose">
      <el-tabs v-model="upmenu">
        <el-tab-pane label="文件上传" name="file"></el-tab-pane>
        <el-tab-pane label="文本上传" name="txt"></el-tab-pane>
        <el-tab-pane label="网页采集" name="web"></el-tab-pane>
        <el-tab-pane label="公众号采集" name="wx"></el-tab-pane>
      </el-tabs>
      <div style="width:100%;text-align: center;" v-if="upmenu == 'file'">
        <p class="upwebtip">支持上传TXT、XML、DOCX、PPT、
          PPTX、XLS格式文件。 文档支持20MB以内。</p>
        <el-upload class="upload-demo" :before-upload="filechange" multiple drag action="">

          <img src="../img/uploadimg.png" style="width:150px;margin: 20px 0" />
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div style="width:100%;text-align: right;" v-else-if="upmenu == 'txt'">
        <div class="mydialog">
          <el-input placeholder="请输入问题" v-model="uptitle" style="margin-bottom: 15px;">
          </el-input>
          <el-input placeholder="请输入内容" type="textarea" v-model="upcont" :rows="7">
          </el-input>
        </div>
        <el-button size="small" type="primary" style="margin-top: 20px;" @click="saveuptxt">保 存</el-button>
      </div>
      <div style="width:100%;text-align: right;" v-else-if="upmenu == 'web'">
        <div class="upwebtip">
          请避免非法抓取他人网站的侵权行为，保证链接可公开访问，且网站内容可复制<br />
          可抓取的网页仅为内容固定不变的静态网页，例如新闻文章、产品介绍等
        </div>
        <el-input placeholder="输入想要采集的网页地址，用英文,隔开" type="textarea" v-model="upweburl" :rows="4">
        </el-input>
        <el-button size="small" type="primary" style="margin-top: 20px;" @click="saveupweb">采 集</el-button>
      </div>
      <div style="width:100%;text-align: right;" v-else-if="upmenu == 'wx'">
        <div class="upwebtip">
          请避免未经授权抓取他人公众号原创文章的侵权行为
        </div>
        <div class="wxitem">
          <div class="wxitenleft">搜索名称</div>
          <el-autocomplete class="inline-input" v-model="wxname" style="width: 100%;" :fetch-suggestions="querySearch"
            placeholder="请输入公众号名称" :trigger-on-focus="false" @select="handleSelect">
            <template slot-scope="{ item }">
              <div class="wxselectname">
                <img :src="item.head">{{ item.value }}
              </div>
            </template>
          </el-autocomplete>
        </div>
        <div class="wxitem" style="margin-top: 30px;">
          <div class="wxitenleft">采集内容</div><el-select style="width: 100%;" v-model="wxnum" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

        <el-button size="small" type="primary" style="margin-top: 20px;" @click="savewx">采集</el-button>
      </div>

    </el-dialog>
  </div>
</template>
<script>
import { addQuestion, upTxttoFilelist, upWebtoFilelist, saveQuestions, saveWxQuestions } from '@/api/klg'
import qs from 'qs'
export default {
  name: '',
  components: {

  },
  data(props) {
    return {
      filelist: [],
      fileed: 0,
      aibox: props.aiboxitem,
      type: 'first',
      inputtitle: '',
      inputcontent: '',
      page: 1,
      wdcount: 0,
      smenu: '问答集',
      dialogVisible: false,
      quedetail: [],
      isscing: false,
      cquelist: [],
      shstatus: false,
      cqindex: 0,
      filename: '',
      cqans: '',
      cqtitle: '',
      wxlist: [],
      uptitle: '',
      upcont: '',
      cqloading: false,
      uploadstatus: false,
      updata: [],
      upmenu: 'file',
      upweburl: '',
      wxnum: '10',
      wxname: '',
      wxed: '',
      options: [{
        value: '10',
        label: '最近10条'
      }, {
        value: '20',
        label: '最近20条'
      }, {
        value: '50',
        label: '最近50条'
      }, {
        value: '100',
        label: '最近100条'
      }]
    }
  },
  props: {
    aiboxitem: { type: Object, default: () => [] },
  },
  mounted() {
    this.getlist()

  },
  watch: {
  },
  methods: {
    fileclick(e) {
      this.fileed = e
    },
    querySearch(e, cb) {
      console.log('输入', e)
      let xhr = new XMLHttpRequest()
      xhr.open('GET', '/queapi/file/select_wechat_official_account?query=' + e + '&page=1')
      xhr.send();
      const that = this;
      const alist = []
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {  //固定写法
          //数据获取成功，获取服务器响应的数据
          const res = JSON.parse(xhr.responseText)
          res.data.forEach(item => {
            alist.push({
              value: item.nickname,
              head: item.round_head_img,
              fakeid: item.fakeid
            })
          });
          console.log(res)
        }
      }
      cb(alist);
    },
    handleSelect(e) {
      console.log(e)
      this.wxed = e
    },
    handleClose() {
      this.dialogVisible = false
    },
    shClose() {
      this.shstatus = false;
      this.uploadstatus = false
    },
    typeclick(e) {
      this.type = e
    },
    savewx() {
      const params = `kb_name=${this.aibox.klgname}&account=${this.wxed.value}&count=${this.wxnum}&fakeid=${this.wxed.fakeid}`

      this.$message.success('采集中..')
      this.uploadstatus = false
      saveWxQuestions(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success('采集成功')
          setTimeout(() => {
            this.getlist()
          }, 200);

        } else {
          this.$message.error(res.message)
        }
      })
    },
    saveuptxt() {
      console.log(this.uptitle, this.upcont)
      const params = `kb_name=${this.aibox.klgname}&title=${this.uptitle}&content=${this.upcont}`

      upTxttoFilelist(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success('保存成功')
          this.uploadstatus = false
          setTimeout(() => {
            this.getlist()
          }, 200);

        } else {
          this.$message.error(res.message)
        }
      })
    },
    saveupweb() {
      console.log(this.upweburl)
      // const params = new URLSearchParams()
      // params.append('kb_name', this.aibox.klgname)
      // params.append('url', this.upweburl)
      const params = `kb_name=${this.aibox.klgname}&url=${this.upweburl}`

      upWebtoFilelist(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success('保存成功')
          this.uploadstatus = false
          setTimeout(() => {
            this.getlist()
          }, 200);

        } else {
          this.$message.error(res.message)
        }
      })
    },
    savequestions() {
      // const params = new URLSearchParams()
      // params.append('kb_name', this.aibox.klgname)
      // params.append('file_name', this.filename)
      const params = `kb_name=${this.aibox.klgname}&file_name=${this.filename}`

      saveQuestions(params).then(res => {
        if (res.code == 200) {
          this.$message.success('转存成功')
          this.shstatus = false
        } else {
          this.$message.error(res.message)
        }
      })
    },
    pagec(type) {
      this.cqindex = type == 0 ? (this.cqindex - 1) : (this.cqindex + 1);

      this.cqans = this.cquelist[this.cqindex].answer;
      this.cqtitle = this.cquelist[this.cqindex].question_title;
    },
    upmenuclick(e) {
      console.log(e)
      this.upmenu = e
    },
    editque(row) {
      this.dialogVisible = true;
      this.inputcontent = row.answer;
      this.inputtitle = row.question_title;
      this.quedetail = row;
    },
    gettimeresult(date) {
      return new Date(date * 1000).getFullYear() + '-' + (new Date(date *
        1000).getMonth() + 1) + '-' + new Date(date * 1000).getDate()
    },
    openadddialog() {
      this.dialogVisible = true;
      this.inputcontent = '';
      this.inputtitle = '';
      this.quedetail = [];
    },
    createquelist(filename, index) {
      this.filelist[index].is_review = -1
      let xhr = new XMLHttpRequest()
      // xhr.open('GET', '/queapi/question_tmp/file?kb_name=' + this.aibox.klgname)
      xhr.open('GET', '/queapi/question_tmp/?kb_name=' + this.aibox.klgname + '&file_name=' + filename)
      // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Content-type", "application/json");
      //xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded"); // 设置请求头
      xhr.send();
      const that = this;
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {  //固定写法
          //数据获取成功，获取服务器响应的数据
          const res = JSON.parse(xhr.responseText)
          that.filelist[index].is_review = 1
          that.getlist()
          console.log(res)
        }
      }
    },
    showquelist(filename) {
      this.filename = filename;
      let xhr = new XMLHttpRequest()
      // xhr.open('GET', '/queapi/question_tmp/file?kb_name=' + this.aibox.klgname)
      xhr.open('GET', '/queapi/question_tmp/?kb_name=' + this.aibox.klgname + '&file_name=' + filename)
      // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Content-type", "application/json");
      //xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded"); // 设置请求头
      xhr.send();
      const that = this;
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {  //固定写法
          //数据获取成功，获取服务器响应的数据
          const res = JSON.parse(xhr.responseText)
          that.cquelist = res.data;
          if (res.data.length > 0) {
            that.cqans = res.data[0].answer;
            that.cqtitle = res.data[0].question_title;
          }

          that.shstatus = true
          console.log(res)
        }
      }
    },
    saveque() {
      this.cqloading = true
      // const params = new URLSearchParams()
      // params.append('answer', this.cqans)
      // params.append('app_code', this.aibox.appcode)
      // params.append('is_ai', 1)
      // params.append('question_title', this.cqtitle)
      // params.append('question_type', this.aibox.klgname)
      // const params = `app_code=${this.aibox.appcode}&question_title=${this.cqtitle}&answer=${this.cqans}&is_ai=1&question_type=${this.aibox.klgname}`
      const params = {
        app_code: this.aibox.appcode,
        question_title: this.cqtitle,
        answer: this.cqans,
        is_ai: 1,
        status: 1,
        question_type: this.aibox.klgname
      }

      addQuestion(qs.stringify(params)).then(res => {
        console.log(res);
        if (res.code == 200) {
          // this.$message.success('转存成功')
          this.qyque()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    qyque() {
      this.cqloading = true
      let xhr = new XMLHttpRequest()
      xhr.open('DELETE', '/queapi/question_tmp/' + this.cquelist[this.cqindex].id)
      // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Content-type", "application/json");
      //xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded"); // 设置请求头
      xhr.send();
      const that = this;
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {  //固定写法
          //数据获取成功，获取服务器响应的数据
          const res = JSON.parse(xhr.responseText)
          if (res.code == 200) {
            that.$message.success(res.message)
            that.showquelist(that.filename)
            that.cqloading = false
          } else {
            that.$message.error(res.message)
          }
        }
      }
    },
    qyquestions() {
      this.cqloading = true
      let xhr = new XMLHttpRequest()
      xhr.open('DELETE', '/queapi/question_tmp?kb_name=' + this.aibox.klgname + '&file_name=' + this.filename)
      // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Content-type", "application/json");
      //xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded"); // 设置请求头
      xhr.send();
      const that = this;
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {  //固定写法
          //数据获取成功，获取服务器响应的数据
          const res = JSON.parse(xhr.responseText)
          if (res.code == 200) {
            that.$message.success(res.message)
            that.showquelist(that.filename)
            that.cqloading = false
          } else {
            that.$message.error(res.message)
          }
        }
      }
    },


    back() {
      this.$emit('changetype', 'ai')
    },
    getlist() {
      let xhr = new XMLHttpRequest()
      xhr.open('GET', '/queapi/question_tmp/file?kb_name=' + this.aibox.klgname)
      // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Content-type", "application/json");
      //xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded"); // 设置请求头
      xhr.send();
      const that = this;
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {  //固定写法
          //数据获取成功，获取服务器响应的数据
          const res = JSON.parse(xhr.responseText)
          res.data.sort(function (a, b) {
            return new Date(b.create_time).getTime() - new Date(a.create_time).getTime()
          });
          that.filelist = res.data
          // console.log(that.filelist)
        }
      }
    },
    download() {
      let xhr = new XMLHttpRequest()
      xhr.open('GET', '/klgapi/knowledge_base/download_doc?knowledge_base_name=' + this.aibox.klgname + '&file_name=' + this.filelist[this.fileed].file_name + '&preview=false')
      // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Content-type", "application/json");
      //xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded"); // 设置请求头
      xhr.send();
      const that = this;
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {  //固定写法
          //数据获取成功，获取服务器响应的数据
          const res = JSON.parse(xhr.responseText)
          console.log(res)
          window.open(res.downloadurl)
        }
      }
    },
    goupload() {
      // console.log(document.getElementById('inputfile'))
      // document.getElementById('inputfile').click();
      this.uploadstatus = true;
      this.updata = {
        knowledge_base_name: this.aibox.klgname
      }
    },
    filechange(e) {
      console.log(e)
      this.upload(e)
    },
    upload(file) {
      console.log(file)
      if (!file) return; // 没有选择文件
      if (file.size / 1024 / 1024 / 20 >= 1) {
        this.$message.error('文件大小不允许超过20MB');
        return;
      }
      var xhr = new XMLHttpRequest();
      // 处理上传进度
      xhr.upload.onprogress = function (event) {
        var percent = 100 * event.loaded / event.total;
        console.log('上传进度：' + percent + '%');
      };
      // this.$message.error('上传失败');
      xhr.onerror = function () {
        this.$message.error('上传失败');
      }
      // 发送请求
      xhr.open('POST', '/klgapi/knowledge_base/upload_docs', true);
      var formData = new FormData();
      formData.append('files', file);
      formData.append('knowledge_base_name', this.aibox.klgname)
      xhr.send(formData);
      const that = this
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {  //固定写法
          //数据获取成功，获取服务器响应的数据
          const res = JSON.parse(xhr.responseText)
          console.log(res)
          if (res.code == 200) {
            that.$message.success(res.msg)
            that.getlist()
          } else {
            that.$message.error(res.msg)
          }
        }
      }
    },
    delfile(filename) {
      let xhr = new XMLHttpRequest()
      xhr.open('POST', '/klgapi/knowledge_base/delete_docs')
      // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Content-type", "application/json");
      //xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded"); // 设置请求头
      xhr.send( // 设置需要携带到后端的字段，字符串形式
        JSON.stringify({
          "knowledge_base_name": this.aibox.klgname,
          "file_names": [
            filename
          ],
          "delete_content": true,
          "not_refresh_vs_cache": true
        })
      );
      const that = this;
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {  //固定写法
          //数据获取成功，获取服务器响应的数据
          const res = JSON.parse(xhr.responseText)
          console.log(res)
          if (res.code == 200) {
            that.$message.success('删除成功')
            that.getlist()
          }
        }
      }

    }
  }
}
</script>
<style lang='less' scoped>
.klgmain {
  height: calc(100%-10px);
  width: 100%;
  border-radius: 10px 0 0 10px;
  font-family: Alibaba PuHuiTi;

  ::-webkit-scrollbar {
    width: auto;
    background-color: #1c2044;
  }


  /*竖直的滑块*/
  ::-webkit-scrollbar-thumb {
    background-color: rgb(20, 61, 107);
    border-radius: 2px;
    border-top: 1px solid rgb(20, 61, 107);
    border-bottom: 1px solid rgb(20, 61, 107);
    border-left: 1px solid rgb(20, 61, 107);
  }

  .bottop {
    width: 100%;
    height: 80px;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    padding: 20px 30px;
    font-size: 20px;

    .menuitem {
      width: 300px;
      height: 40px;
      cursor: pointer;
      background: linear-gradient(275deg, #22254d 0%, #272a57 100%);
      border-radius: 12px;
      color: white;
      text-align: center;
      line-height: 40px;
      margin-right: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;

    }

    .med {
      background: #FF009D;
    }

    /deep/ .el-tabs__item {
      font-size: 18px;
      text-decoration: 10px;
      letter-spacing: 2px;
    }

    .back {
      position: absolute;
      left: 20px;
      top: 15px;
      cursor: pointer;

      .icon {
        width: 50px;
      }
    }

    .botleft {
      width: calc(100% - 100px);
      height: 80px;
      padding: 10px 20px;
      background-color: white;
      font-size: 32px;
      text-align: center;
      font-weight: bold;
      border-radius: 8px;
      line-height: 60px;

    }

    .bottopright {
      width: 80px;
      height: 80px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 32px;
      }
    }
  }


  .klgcontent {
    border-radius: 10px;
    overflow: hidden;
    height: calc(100% - 60px);

    .fbbox {
      position: relative;
      height: 20px;

      .fbtop {
        height: 40px;
        background: rgba(28, 34, 54, 0.06);
        border-radius: 6px;
        display: flex;
        width: fit-content;
        position: absolute;
        bottom: -40px;
        left: 20px;

        .fbitem {
          height: 32px;
          width: 80px;
          border-radius: 5px;
          line-height: 32px;
          text-align: center;
          cursor: pointer;
          margin: 4px;
          font-size: 14px;
          background: linear-gradient(275deg, #181B40 0%, #181A37 100%);
          color: #FF009D;
        }

        .checked {
          background: #FF009D;
          color: #fff;
        }
      }
    }


    .detailbox {
      padding: 0 20px;

      .detail {
        font-size: 24px;
        color: #fff;
        font-weight: bold;
        display: flex;
        margin-top: 10px;

        .detailitem {
          text-align: center;
          margin: 0 20px;

          .dname {
            color: #DBDBFF;
            font-size: 16px;
          }

          .dvalue {
            font-size: 20px;
            background: linear-gradient(to right, #9953FF, #53A9FF);
            /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/
            -webkit-background-clip: text;
            /*将设置的背景颜色限制在文字中*/
            -webkit-text-fill-color: transparent;

            span {
              font-size: 14px
            }
          }
        }
      }

      .tcont {
        font-size: 16px;
        display: flex;
        background: linear-gradient(180deg, #181B40 0%, #181A37 100%);
        border-radius: 12px;
        margin-top: 10px;

        .zskitem {
          color: #7A7A9D;
          margin: 10px 20px;
          height: 30px;
          line-height: 30px;
        }

        .zname {
          font-size: 18px;
          color: #FF009D
        }
      }
    }

    .nodatabox {
      cursor: pointer;
      width: 50%;
      text-align: center;
      margin: 30px auto;

      .nodatatxt {
        font-size: 24px;
        margin-top: 30px;
      }

      img {
        margin-top: 10%;
        width: 40%;
      }
    }

    .uploadbox {
      width: 100%;
      text-align: center;
      padding: 20px;
      border-radius: 8px;
      position: relative;

      #inputfile {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }

      img {
        height: 60px;
      }

      .tip {
        font-size: 18px;
        margin-top: 20px;
      }
    }

    .klgtablebox {
      width: 100%;
      text-align: center;
      padding: 20px;
      border-radius: 8px;
      padding-top: 0;

      .upbtndiv {
        width: 100%;
        display: flow-root;
        font-size: 20px;
        text-align: right;

        .filecount {
          background-color: #eeecec;
          font-size: 14px;
          padding: 4px 10px;
          border-radius: 4px;
          margin-right: 15px;

          .cnum {
            color: #0988F6;
            font-size: 16px;
            margin: 0 5px;
            font-weight: bold;
          }
        }

        .upbtn {
          width: 120px;
          height: 32px;
          color: #FF009D;
          border: 1px solid #FF009D;
          border-radius: 8px;
          line-height: 32px;
          font-size: 18px;
          float: right;
          margin-bottom: 10px;
          cursor: pointer;
          text-align: center;
        }
      }

      table {
        width: 100%;
        margin-bottom: 20px;
        border-collapse: collapse;
        border-right: 1px solid #e4e4e4;
        border-top: 1px solid #e4e4e4;

        thead {
          background-color: #F2F2F3;
          color: #fff;
          width: 100%;
          display: inline-table;

          tr {
            th {
              padding: 10px;
              font-size: 16px;
              border-left: 1px solid #e4e4e4;
              border-bottom: 1px solid #e4e4e4;
            }
          }
        }

        .t1 {
          width: 5%;
        }

        .t2 {
          width: 39%;
        }

        .t3 {
          width: 12%;
        }

        .t4 {
          width: 20%;
        }

        tbody {
          max-height: 550px;
          overflow-y: auto;
          display: block;
          width: 100%;



          tr {
            cursor: pointer;
            display: flex;

            td {
              padding: 10px;
              font-size: 17px;
              border: 0;
              border-left: 1px solid #e4e4e4;
              border-bottom: 1px solid #e4e4e4;
            }
          }

          .fileed {
            background-color: #cde4f7;
          }
        }
      }

      .pagelist {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 20px 0;
        font-size: 16px;
        line-height: 30px;

        .left1 {}

        .right1 {
          color: #fff;
          font-size: 13px;
          margin: 10px 0;
        }
      }
    }


  }

  .btnbox {
    display: flex;
    justify-content: center;

    .btnitem {
      width: 240px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: 20px;
      background-color: #263754;
      color: white;
      font-weight: bold;
      border-radius: 8px;
      margin: 20px;
      cursor: pointer;
    }
  }

  .mydialog {
    z-index: 2001;

    p {
      margin-top: 20px;
      margin-bottom: 10px;
      padding-left: 5px;
    }
  }
}

.nodata {
  font-size: 13px;
  text-align: center;
  color: #fff;
}

.upwebtip {
  font-size: 13px;
  text-align: left;
  margin-bottom: 15px;
  color: #4E5E83;
}

.aidiv {
  width: 25px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  font-size: 13px;
  margin: 0 auto;
}

.wxitem {
  width: 100%;
  display: flex;

  .wxitenleft {
    width: 100px;
    text-align: left;
    line-height: 35px;
  }
}

.wxselectname {
  display: flex;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

/deep/ .el-table tr {
  background-color: #1c2044 !important;
  color: #DDECFF !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: #202652 !important;

  color: #FF009D;
}

/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf,
/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table--border {
  border: 1px solid #181A37;
  border-right: 1px solid #181A37;
  border-bottom: 1px solid #181A37;
  color: #DDECFF !important;
}


/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #fff;
}

/deep/ .el-pagination span {
  font-size: 15px
}


.el-table .el-table__body td,
.el-table .el-table__header th {
  border-right: none;
  border-bottom: none;
}

.el-table .el-table__body td:last-child,
.el-table .el-table__header th:last-child {
  border-right: 1px solid #dcdfe6;
  /* 根据需要可以添加或去掉这一行代码 */
}

.el-table .el-table__footer td,
.el-table .el-table__header th {
  border-bottom: none;
}

/deep/ .el-popover {
  position: absolute;
  background: #fff;
  min-width: 150px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  padding: 12px;
  z-index: 2000;
  color: #fff;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  word-break: break-all;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active,
/deep/ .el-pagination.is-background .btn-next,
/deep/ .el-pagination.is-background .btn-prev,
/deep/ .el-pagination.is-background .el-pager li {
  background-color: #202652 !important;
  color: #fff;
}


/deep/ .el-pagination__jump,
/deep/ .el-pagination__total {
  color: #DDECFE;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #FF009D;
}

/deep/ .el-upload-dragger {
  background: linear-gradient(275deg, #181B40 0%, #181A37 100%);
  box-shadow: -2px -2px 2px #1E213B;
  border-radius: 5px;
  border-color: #1c2044;

}

/deep/ .el-upload-dragger .el-upload__text {
  color: white;
}

/deep/ .el-upload-dragger .el-upload__text em {
  color: #FF009D;
}

//去除右侧白线
.el-table--border::after {
  width: 0px;
}

.el-table__header-wrapper {
  background: none;
}

.el-table::before {
  background: none
}

/* 取消el-table鼠标移入高亮 */
div /deep/ .el-table tbody tr:hover>td {
  background: #0e1341 !important
}




/* 取消el-table鼠标移入高亮 */
.el-table__body tr:hover>td {
  background-color: transparent !important;
}

/deep/ .el-dialog {
  background: linear-gradient(180deg, #0e1341 0%, #181A37 100%);
  box-shadow: 3px 3px 3px rgba(17, 19, 38, 0.82);
  border-radius: 13px;

  .mydialog {
    color: #FFF;
    font-size: 18px;




  }

}

/deep/ .el-input__inner,
/deep/ .el-textarea__inner {
  color: white;
  background: linear-gradient(275deg, #181B40 0%, #181A37 100%);
  box-shadow: -2px -2px 2px #1E213B;
  border-radius: 5px;
  border: 1px solid rgba(255, 0, 157, 0.302);
}

/deep/ .el-button--default {
  border-radius: 3px;
  border: 1px solid #A30202;
  background: #1E213B;
  color: #A30202;

}

/deep/ .el-button--primary {
  background: #FF009D;
  color: white;
  border: 0;
}

/deep/ .el-tabs__item {
  color: #F2F2F3;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-tabs__active-bar {
  background-color: #FF009D;
}

/deep/ .el-table__body-wrapper {
  background: #1c2044;
}

/deep/ .el-tabs__item.is-active {
  color: #FF009D;
}


/deep/ .el-dialog__title,
/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #FF009D !important;
  font-size: 22px
}
</style>
