<template>
  <div class="indmain">
    <div class="indleft">
      <div class="leftitem item1">
        <img src="@/assets/images/zqzs/yxx.gif" />
      </div>
      <div @click="menuclick(i + 1)" :class="menued == (i + 1) ? 'leftitem item2 itemed' : 'leftitem item2'"
        v-for="(item, i) in leftlist" :key="i">
        <div class="icon"><img :src="menued == (i + 1) ? item.imged : item.img" /></div>
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
    <div class="indright">
      <One v-if="menued == 1" />
      <Two v-if="menued == 2" />
      <Thr v-if="menued == 3" />
      <Five v-if="menued == 5" />
      <Six v-if="menued == 6" />
      <Sev v-if="menued == 7" />
    </div>
  </div>
</template>
<script>
import One from './comps/1.vue'
import Two from './comps/2.vue'
import Thr from './comps/3.vue'
import Five from './comps/5.vue'
import Six from './comps/6.vue'
import Sev from './comps/7.vue'
export default {
  name: '',
  components: { One, Two, Thr, Five, Six, Sev },
  data() {
    return {
      leftlist: [{
        name: '环境控温及优化',
        img: require('./img/2.png'),
        imged: require('./img/2ed.png'),
      }, {
        name: '设备运行状态监控',
        img: require('./img/3.png'),
        imged: require('./img/3ed.png'),
      }, {
        name: '制造设备逻辑控制',
        img: require('./img/4.png'),
        imged: require('./img/4ed.png'),
      }, {
        name: '制造设备运行优化',
        img: require('./img/5.png'),
      }, {
        name: '无人值守处置',
        img: require('./img/6.png'),
        imged: require('./img/6ed.png'),
      }, {
        name: '能耗优化管理',
        img: require('./img/7.png'),
        imged: require('./img/7ed.png'),
      }, {
        name: '安全环保管理',
        img: require('./img/8.png'),
        imged: require('./img/8ed.png'),
      },],
      menued: 1
    }
  },
  props: {
  },
  mounted() {

  },
  watch: {
  },
  methods: {
    menuclick(e) {
      if (e == 4) return
      this.menued = e
    }
  }
}
</script>
<style lang='less' scoped>
.indmain {
  height: calc(100% - 40px);
  width: 100%;
  border-radius: 10px 0 0 10px;
  margin-top: 20px;
  padding: 50px;
  display: flex;

  .indleft {
    width: 45%;
    display: flex;
    flex-wrap: wrap;

    .leftitem {
      width: calc(33.3% - 40px);
      text-align: center;
      background: linear-gradient(275deg, #181B40 0%, #181A37 100%);

      border-radius: 8px;
      margin: 20px;

      .icon {
        img {
          width: 45%;
          margin-top: 40px;
        }
      }

      .name {
        color: #ABAFDB;
        font-size: 18px;
        margin-top: 30px;
      }
    }

    .item2 {
      box-shadow: -1px -1px 2px #1E213B;
      cursor: pointer;
    }

    .itemed {
      border: 1px solid #ff00ed;

      .name {
        color: white
      }

    }

    .item1 {
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 80%;
      }
    }
  }

  .indright {
    width: 55%;
  }


}
</style>
