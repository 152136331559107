<template>
  <div class="compsmain">
    <div class="left">
      <div class="icon">
        <img src="../img/zndb.png" alt="">
      </div>
      <div class="text">智能<br />电表</div>
    </div>
    <div class="right" v-loading="loading" element-loading-text="指令发送中.." element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)">
      <div class="ritem">
        <div :class="status == 1 ? 'icon iconed' : 'icon'" @click="djclick(1)">
          <div class="img">
            <img :src="status == 1 ? require('../img/dbdsed.png') : require('../img/dbds.png')" alt="">
          </div>
        </div>
        <div class="text">电表读数</div>
      </div>
      <div class="ritem">
        <div :class="status == 2 ? 'icon iconed' : 'icon'" @click="djclick(2)">
          <div class="img">
            <img :src="status == 2 ? require('../img/dyed.png') : require('../img/dy.png')" alt="">
          </div>
        </div>

        <div class="text">当前电压</div>
      </div>
      <div class="ritem">
        <div :class="status == 3 ? 'icon iconed' : 'icon'" @click="djclick(3)">
          <div class="img">
            <img :src="status == 3 ? require('../img/dled.png') : require('../img/dl.png')" alt="">
          </div>
        </div>
        <div class="text">当前电流</div>
      </div>
      <div class="ritem">
        <div :class="status == 4 ? 'icon iconed' : 'icon'" @click="djclick(4)">
          <div class="img">
            <img :src="status == 4 ? require('../img/hdled.png') : require('../img/hdl.png')" alt="">
          </div>
        </div>
        <div class="text">当前耗电量</div>
      </div>
      <div class="ritem" v-if="(status == 2 || status == 3) && resultdata.length > 0">
        <div class="valueitem2" v-for="(item, i) in resultdata" :key="i">
          <div class="value2">{{ item.substr(item.indexOf('：') + 1) }}</div>
          <div class="text2">{{ item.substr(0, item.indexOf('：')) }}</div>
        </div>
      </div>
      <div class="ritem" v-else-if="(status == 1 || status == 4) && resultdata.length > 0">
        <div class="valueitem" v-for="(item, i) in resultdata.substr(0, resultdata.indexOf('k'))" :key="i">{{ item }}
        </div>&nbsp;kWh
      </div>

      <div class="ritem">
        <img src="../img/tip.png" alt="">
        <span v-if="(status == 1 || status == 4) && resultdata.length > 0">{{ quelist[status] }}为：{{
      resultdata.substr(0, resultdata.indexOf('k'))
    }}</span>
        <span v-if="(status == 2 || status == 3) && resultdata.length > 0">{{ quelist[status] }}为：{{
      resultdata[0]
          }}</span>
      </div>


    </div>
  </div>
</template>
<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      value: false,
      status: '1',
      loading: false,
      resultdata: [],
      quelist: ['', '电表读数', '当前电压', '当前电流', '当前耗电量']
    }
  },
  props: {

  },
  mounted() {
    this.fetchdata(this.quelist[this.status])
  },
  methods: {
    fetchdata(que) {
      this.loading = true;
      let xhr = new XMLHttpRequest()
      xhr.open('POST', '/klgapi/chat/gygpt_chat', true)
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Content-type", "application/json");
      xhr.send(JSON.stringify({
        query: que,
        stream: false,
        model_name: '英鸣产业大模型',
        temperature: '0.7',
        mode: '晓知因',
        source: 'CBox产品演示',
        clienttype: 'WEB'
      }));
      const that = this;
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {  //固定写法
          //数据获取成功，获取服务器响应的数据 
          const res = JSON.parse(xhr.responseText)
          that.loading = false
          if (that.status == 1 || that.status == 4) {
            that.resultdata = res.answer.split('\n')[res.answer.split('\n').length - 1].split('：')[1]

          } else {
            that.resultdata = res.answer.split('\n')
          }
          console.log('aa', that.resultdata)

        }
      }
    },
    djclick(e) {
      this.status = e
      this.resultdata = []
      setTimeout(() => {
        this.fetchdata(this.quelist[e])
      }, 100);

    }
  }
}
</script>
<style lang='less' scoped>
.compsmain {
  height: calc(100% - 40px);
  width: 100%;
  border-radius: 10px 0 0 10px;
  margin-top: 20px;
  display: flex;
  background: linear-gradient(180deg, #181B40 0%, #181A37 100%);
  box-shadow: 2px 2px 2px rgba(17, 19, 38, 0.82);
  border-radius: 7px;

  .left {
    width: 150px;
    background: linear-gradient(180deg, #565AB5 0%, #800A52 100%);
    box-shadow: 2px 2px 2px rgba(17, 19, 38, 0.82);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;

    .icon {
      width: 100%;
      text-align: center;

      img {
        margin: 0 auto;
      }
    }

    .text {
      font-size: 30px;
      margin-top: 20px;
      color: white;
      text-align: center;
    }
  }

  .right {
    width: calc(100% - 150px);
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 20px;

    .ritem {
      background: linear-gradient(275deg, #181B40 0%, #181A37 100%);
      box-shadow: 0px 0px 16px rgba(56, 41, 98, 0.42);
      border-radius: 10px;
      padding: 20px;
      margin: 20px;
      width: calc(25% - 40px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      align-content: center;

      .icon {
        width: 80px;
        height: 80px;
        background: linear-gradient(180deg, #181A37 0%, #222546 100%);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        align-content: center;
        margin: 10px 0;
        cursor: pointer;

        .img {
          img {
            width: 40px;
          }
        }

        .dvalue {
          text-align: center;
          color: white;
          font-weight: 600;
          width: 100%;
          font-size: 16px;
        }
      }

      .iconed {
        background: linear-gradient(180deg, #0EDFF0 0%, #1B36F8 100%);
        border-radius: 50%;
      }

      .text {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        align-content: center;
        font-size: 16px;
        color: white;
        text-overflow: ellipsis;


      }

      .valueitem {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 80px;
        font-size: 60px;
        font-weight: 800;
        border-radius: 8px;
        margin: 0 2px;
        text-align: center;
        background: linear-gradient(180deg, #0EDFF0 0%, #1B36F8 100%);
      }

      .valueitem2 {
        width: calc(33.3% - 40px);
        margin: 20px;
        background-color: #1C204D;
        padding: 10px;
        border-radius: 8px;

        .value2 {
          font-size: 40px;
          font-weight: bold;
          color: #ff009d;
        }

        .text2 {
          font-size: 16px;
        }
      }


    }

    .ritem:nth-child(5) {
      justify-content: flex-end !important;
    }

    .ritem:nth-child(5),
    .ritem:nth-child(6) {
      width: 100%;
      margin: 40px 20px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      align-content: center;
      font-size: 22px;
      color: white;

      img {
        margin-right: 15px;
      }
    }


  }

}
</style>
