<template>
  <div class="klgmain">
    <div class="klgcontent">
      <div class="klgtablebox">
        <div
          class="nodatabox"
          @click="openadddialog"
          v-if="filelist.length == 0"
        >
          <img src="../img/uploadimg.png" alt="" />
          <div class="nodatatxt">点击图片,录入知识库吧</div>
          <!-- <div class="nodatatxt"><el-button size="small" @click="openadddialog">录入问答</el-button></div> -->
        </div>
        <span v-else>
          <div class="upbtndiv">
            <!-- <span class="filecount">共<span class="cnum">{{ wdcount }}</span>个问答，已训练<span class="cnum">{{
        wdcount }}</span>个</span> -->
            <div class="upbtn" @click="openadddialog">录入问答</div>
          </div>
          <el-table :data="filelist" height="360" border style="width: 100%">
            <el-table-column prop="id" align="center" label="序号" width="80" />
            <el-table-column prop="question_title" label="文件名" />
            <el-table-column align="center" label="是否AI生成" width="120">
              <template slot-scope="scope">
                <div
                  class="aidiv"
                  :style="{
                    background: scope.row.is_ai == 0 ? 'green' : 'orange',
                  }"
                >
                  {{ scope.row.is_ai == 0 ? '否' : '是' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="状态" width="180">
              <template slot-scope="scope">
                <div>学习完成</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="创建时间" width="180">
              <template slot-scope="scope">
                <div>{{ gettimeresult(scope.row.create_time) }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="160">
              <template slot-scope="scope">
                <div>
                  <el-button
                    size="mini"
                    @click="editque(scope.row)"
                    type="primary"
                    >修改</el-button
                  >&nbsp;
                  <el-popconfirm
                    title="确定删除选中文件吗？"
                    @confirm="delque(scope.row.id)"
                  >
                    <el-button slot="reference" size="mini" type="danger"
                      >删除</el-button
                    >
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="No" label="状态" width="180"> -->
            <!-- </el-table-column> -->
          </el-table>
          <div class="pagelist">
            <div class="left1">
              <el-pagination
                background
                page-size="10"
                :total="wdcount"
                @current-change="pagechange"
              >
              </el-pagination>
            </div>
            <div class="right1">
              {{ aibox.name }}问答集已有文件{{ wdcount }}个
            </div>
          </div>
        </span>
      </div>
    </div>
    <el-dialog
      title="录入问答"
      :append-to-body="true"
      :visible="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <div class="mydialog">
        <p style="margin: 0 0 10px 0">问题：</p>
        <el-input placeholder="请输入问题" v-model="inputtitle"> </el-input>
        <p style="margin: 30px 0 10px 0">答案：</p>
        <el-input
          placeholder="请输入答案"
          type="textarea"
          v-model="inputcontent"
          :rows="5"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="submitadd"
          >提交</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { addQuestion, editQuestion, saveQuestions, saveWxQuestions } from '@/api/klg'
import qs from 'qs'
export default {
  name: '',
  components: {

  },
  data(props) {
    return {
      filelist: [],
      fileed: 0,
      aibox: props.aiboxitem,
      type: 'first',
      inputtitle: '',
      inputcontent: '',
      page: 1,
      wdcount: 0,
      smenu: '问答集',
      dialogVisible: false,
      quedetail: [],
      isscing: false,
      cquelist: [],
      shstatus: false,
      cqindex: 0,
      filename: '',
      cqans: '',
      cqtitle: '',
      wxlist: [],
      uptitle: '',
      upcont: '',
      cqloading: false,
      uploadstatus: false,
      updata: [],

    }
  },
  props: {
    aiboxitem: { type: Object, default: () => [] },
  },
  mounted() {
    this.getquelist()

  },
  watch: {
    aiboxitem(n) {
      // console.log(n, 'data')
      this.aibox = n;
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
    },


    savequestions() {
      // const params = new URLSearchParams()
      // params.append('kb_name', this.aibox.klgname)
      // params.append('file_name', this.filename)
      const params = `kb_name=${this.aibox.klgname}&file_name=${this.filename}`

      saveQuestions(params).then(res => {
        if (res.code == 200) {
          this.$message.success('转存成功')
          this.shstatus = false
        } else {
          this.$message.error(res.message)
        }
      })
    },
    pagec(type) {
      this.cqindex = type == 0 ? (this.cqindex - 1) : (this.cqindex + 1);

      this.cqans = this.cquelist[this.cqindex].answer;
      this.cqtitle = this.cquelist[this.cqindex].question_title;
    },
    pagechange(e) {
      console.log(e)
      this.page = e;
      this.getquelist()
    },
    editque(row) {
      this.dialogVisible = true;
      this.inputcontent = row.answer;
      this.inputtitle = row.question_title;
      this.quedetail = row;
    },
    gettimeresult(date) {
      return new Date(date * 1000).getFullYear() + '-' + (new Date(date *
        1000).getMonth() + 1) + '-' + new Date(date * 1000).getDate()
    },
    openadddialog() {
      this.dialogVisible = true;
      this.inputcontent = '';
      this.inputtitle = '';
      this.quedetail = [];
    },

    submitadd() {
      console.log(this.inputtitle, this.inputcontent)
      let params = {};
      const answer = this.inputcontent
      if (this.quedetail.id) {
        params = {
          id: this.quedetail.id,
          app_code: this.aibox.appcode,
          question_title: this.inputtitle,
          answer: answer,
          is_ai: 0,
          question_type: this.aibox.klgname
        }
        // params = `id=${this.quedetail.id}&question_type=${this.aibox.klgname}&app_code=${this.aibox.appcode}&is_ai=0&question_title=${this.inputtitle}&answer=${answer}`
      } else {
        params = {
          app_code: this.aibox.appcode,
          question_title: this.inputtitle,
          answer: answer,
          is_ai: 0,
          question_type: this.aibox.klgname
        }
        // params = `question_type=${this.aibox.klgname}&app_code=${this.aibox.appcode}&is_ai=0&question_title=${this.inputtitle}&answer=${answer}`
      }



      console.log(params)
      if (this.quedetail.id) {
        editQuestion(qs.stringify(params)).then(res => {
          this.dialogVisible = false;
          if (res.code == 200) {
            this.$message.success(res.message)
            this.getquelist()
          } else {
            this.$message.error(res.message)
          }
          setTimeout(() => {
            this.getquelist()
          }, 100);
        })
      } else {
        addQuestion(qs.stringify(params)).then(res => {
          console.log(res);
          if (res.code == 200) {
            this.dialogVisible = false;
            setTimeout(() => {
              this.getquelist()
            }, 100);
          } else {
            this.$message.error(res.msg)
          }

        })
      }

    },
    delque(id) {

      let xhr = new XMLHttpRequest()
      xhr.open('DELETE', '/queapi/question/' + id)
      // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Content-type", "application/json");
      //xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded"); // 设置请求头
      xhr.send();
      const that = this;
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {  //固定写法
          //数据获取成功，获取服务器响应的数据
          const res = JSON.parse(xhr.responseText)
          if (res.code == 200) {
            that.$message.success(res.message)
            that.getquelist()
          } else {
            that.$message.error(res.message)
          }
        }
      }
    },
    getquelist() {
      let xhr = new XMLHttpRequest()
      xhr.open('GET', '/queapi/question/page?page=' + this.page + '&size=10&app_code=' + this.aibox.appcode)
      // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Content-type", "application/json");
      //xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded"); // 设置请求头
      xhr.send();
      const that = this;
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {  //固定写法
          //数据获取成功，获取服务器响应的数据
          const res = JSON.parse(xhr.responseText)
          that.filelist = res.data.records
          that.wdcount = res.data.totalRow
          console.log(res)
        }
      }

    },
  }
}
</script>
<style lang='less' scoped>
.klgmain {
  height: calc(100%-10px);
  width: 100%;
  margin-top: -10px;
  border-radius: 10px 0 0 10px;
  font-family: Alibaba PuHuiTi;

  ::-webkit-scrollbar {
    width: auto;
    background-color: #1c2044;
  }

  /*竖直的滑块*/
  ::-webkit-scrollbar-thumb {
    background-color: rgb(20, 61, 107);
    border-radius: 2px;
    border-top: 1px solid rgb(20, 61, 107);
    border-bottom: 1px solid rgb(20, 61, 107);
    border-left: 1px solid rgb(20, 61, 107);
  }

  .klgcontent {
    border-radius: 10px;
    overflow: hidden;
    height: calc(100% - 60px);

    .fbbox {
      position: relative;
      height: 20px;

      .fbtop {
        height: 40px;
        background: rgba(28, 34, 54, 0.06);
        border-radius: 6px;
        display: flex;
        width: fit-content;
        position: absolute;
        bottom: -40px;
        left: 20px;

        .fbitem {
          height: 32px;
          width: 80px;
          border-radius: 5px;
          line-height: 32px;
          text-align: center;
          cursor: pointer;
          margin: 4px;
          font-size: 14px;
          background: linear-gradient(275deg, #181b40 0%, #181a37 100%);
          color: #ff009d;
        }

        .checked {
          background: #ff009d;
          color: #fff;
        }
      }
    }

    .detailbox {
      padding: 0 20px;

      .detail {
        font-size: 24px;
        color: #fff;
        font-weight: bold;
        display: flex;
        margin-top: 10px;

        .detailitem {
          text-align: center;
          margin: 0 20px;

          .dname {
            color: #dbdbff;
            font-size: 16px;
          }

          .dvalue {
            font-size: 20px;
            background: linear-gradient(to right, #9953ff, #53a9ff);
            /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/
            -webkit-background-clip: text;
            /*将设置的背景颜色限制在文字中*/
            -webkit-text-fill-color: transparent;

            span {
              font-size: 14px;
            }
          }
        }
      }

      .tcont {
        font-size: 16px;
        display: flex;
        background: linear-gradient(180deg, #181b40 0%, #181a37 100%);
        border-radius: 12px;
        margin-top: 10px;

        .zskitem {
          color: #7a7a9d;
          margin: 10px 20px;
          height: 30px;
          line-height: 30px;
        }

        .zname {
          font-size: 18px;
          color: #ff009d;
        }
      }
    }

    .nodatabox {
      cursor: pointer;
      width: 50%;
      text-align: center;
      margin: 30px auto;

      .nodatatxt {
        font-size: 24px;
        margin-top: 30px;
      }

      img {
        margin-top: 10%;
        width: 40%;
      }
    }

    .uploadbox {
      width: 100%;
      text-align: center;
      padding: 20px;
      border-radius: 8px;
      position: relative;

      #inputfile {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }

      img {
        height: 60px;
      }

      .tip {
        font-size: 18px;
        margin-top: 20px;
      }
    }

    .klgtablebox {
      width: 100%;
      text-align: center;
      border-radius: 8px;
      padding-top: 0;

      .upbtndiv {
        width: 100%;
        display: flow-root;
        font-size: 20px;
        text-align: right;

        .filecount {
          background-color: #eeecec;
          font-size: 14px;
          padding: 4px 10px;
          border-radius: 4px;
          margin-right: 15px;

          .cnum {
            color: #0988f6;
            font-size: 16px;
            margin: 0 5px;
            font-weight: bold;
          }
        }

        .upbtn {
          width: 120px;
          height: 32px;
          color: #ff009d;
          border: 1px solid #ff009d;
          border-radius: 8px;
          line-height: 32px;
          font-size: 18px;
          float: right;
          margin-bottom: 10px;
          cursor: pointer;
          text-align: center;
        }
      }

      table {
        width: 100%;
        margin-bottom: 20px;
        border-collapse: collapse;
        border-right: 1px solid #e4e4e4;
        border-top: 1px solid #e4e4e4;

        thead {
          background-color: #f2f2f3;
          color: #fff;
          width: 100%;
          display: inline-table;

          tr {
            th {
              padding: 10px;
              font-size: 16px;
              border-left: 1px solid #e4e4e4;
              border-bottom: 1px solid #e4e4e4;
            }
          }
        }

        .t1 {
          width: 5%;
        }

        .t2 {
          width: 39%;
        }

        .t3 {
          width: 12%;
        }

        .t4 {
          width: 20%;
        }

        tbody {
          max-height: 550px;
          overflow-y: auto;
          display: block;
          width: 100%;

          tr {
            cursor: pointer;
            display: flex;

            td {
              padding: 10px;
              font-size: 17px;
              border: 0;
              border-left: 1px solid #e4e4e4;
              border-bottom: 1px solid #e4e4e4;
            }
          }

          .fileed {
            background-color: #cde4f7;
          }
        }
      }

      .pagelist {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 20px 0;
        font-size: 16px;
        line-height: 30px;

        .left1 {
        }

        .right1 {
          color: #fff;
          font-size: 13px;
          margin: 10px 0;
        }
      }
    }
  }

  .btnbox {
    display: flex;
    justify-content: center;

    .btnitem {
      width: 240px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: 20px;
      background-color: #263754;
      color: white;
      font-weight: bold;
      border-radius: 8px;
      margin: 20px;
      cursor: pointer;
    }
  }

  .mydialog {
    z-index: 2001;

    p {
      margin-top: 20px;
      margin-bottom: 10px;
      padding-left: 5px;
    }
  }
}

.nodata {
  font-size: 13px;
  text-align: center;
  color: #fff;
}

.upwebtip {
  font-size: 13px;
  text-align: left;
  margin-bottom: 15px;
  color: #4e5e83;
}

.aidiv {
  width: 25px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  font-size: 13px;
  margin: 0 auto;
}

.wxitem {
  width: 100%;
  display: flex;

  .wxitenleft {
    width: 100px;
    text-align: left;
    line-height: 35px;
  }
}

.wxselectname {
  display: flex;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

/deep/ .el-table tr {
  background-color: #1c2044 !important;
  color: #ddecff !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: #202652 !important;

  color: #ff009d;
}

/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf,
/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table--border {
  border: 1px solid #181a37;
  border-right: 1px solid #181a37;
  border-bottom: 1px solid #181a37;
  color: #ddecff !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #fff;
}

/deep/ .el-pagination span {
  font-size: 15px;
}

.el-table .el-table__body td,
.el-table .el-table__header th {
  border-right: none;
  border-bottom: none;
}

.el-table .el-table__body td:last-child,
.el-table .el-table__header th:last-child {
  border-right: 1px solid #dcdfe6;
  /* 根据需要可以添加或去掉这一行代码 */
}

.el-table .el-table__footer td,
.el-table .el-table__header th {
  border-bottom: none;
}

/deep/ .el-popover {
  position: absolute;
  background: #fff;
  min-width: 150px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  padding: 12px;
  z-index: 2000;
  color: #fff;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active,
/deep/ .el-pagination.is-background .btn-next,
/deep/ .el-pagination.is-background .btn-prev,
/deep/ .el-pagination.is-background .el-pager li {
  background-color: #202652 !important;
  color: #fff;
}

/deep/ .el-pagination__jump,
/deep/ .el-pagination__total {
  color: #ddecfe;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #ff009d;
}

/deep/ .el-upload-dragger {
  background: linear-gradient(275deg, #181b40 0%, #181a37 100%);
  box-shadow: -2px -2px 2px #1e213b;
  border-radius: 5px;
  border-color: #1c2044;
}

/deep/ .el-upload-dragger .el-upload__text {
  color: white;
}

/deep/ .el-upload-dragger .el-upload__text em {
  color: #ff009d;
}

//去除右侧白线
.el-table--border::after {
  width: 0px;
}

.el-table__header-wrapper {
  background: none;
}

.el-table::before {
  background: none;
}

/* 取消el-table鼠标移入高亮 */
div /deep/ .el-table tbody tr:hover > td {
  background: #0e1341 !important;
}

/* 取消el-table鼠标移入高亮 */
.el-table__body tr:hover > td {
  background-color: transparent !important;
}

/deep/ .el-dialog {
  background: linear-gradient(180deg, #0e1341 0%, #181a37 100%);
  box-shadow: 3px 3px 3px rgba(17, 19, 38, 0.82);
  border-radius: 13px;

  .mydialog {
    color: #fff;
    font-size: 18px;
  }
}

/deep/ .el-input__inner,
/deep/ .el-textarea__inner {
  color: white;
  background: linear-gradient(275deg, #181b40 0%, #181a37 100%);
  box-shadow: -2px -2px 2px #1e213b;
  border-radius: 5px;
  border: 1px solid rgba(255, 0, 157, 0.302);
}

/deep/ .el-button--default {
  border-radius: 3px;
  border: 1px solid #a30202;
  background: #1e213b;
  color: #a30202;
}

/deep/ .el-button--primary {
  background: #ff009d;
  color: white;
  border: 0;
}

/deep/ .el-tabs__item {
  color: #f2f2f3;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-tabs__active-bar {
  background-color: #ff009d;
}

/deep/ .el-table__body-wrapper {
  background: #1c2044;
}

/deep/ .el-tabs__item.is-active {
  color: #ff009d;
}

/deep/ .el-dialog__title,
/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #ff009d !important;
  font-size: 22px;
}
</style>
